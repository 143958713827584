import './main.scss';
import { loadComponents } from '@opten/helper-components';
import { CollapseComponent } from '@opten/collapse';
import { SliderCustom } from './components/slider';
import { BodyContent } from './components/body-content';

loadComponents(
	CollapseComponent,
    SliderCustom,
    BodyContent
);