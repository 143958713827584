import { Component } from '@opten/compiler/metadata/component';

@Component({ className: 'js-body-content', observe: true })
export class BodyContent {
	constructor(public element: HTMLElement) {
		if (window.innerWidth > 1024) {
			this.setFooterHeight();
		}

		window.addEventListener('resize', () => {
			this.setFooterHeight();
		});

		document
			.querySelector('footer button')
			.addEventListener('click', () => {
				this.setFooterHeight();
			});
	}

	private setFooterHeight() {
		if (window.innerWidth > 1024) {
			setTimeout(() => {
				this.element.setAttribute(
					'style',
					'margin-bottom:' +
						document.querySelector('footer').scrollHeight +
						'px'
				);
			}, 100);
		} else {
			this.element.removeAttribute('style');
		}
	}
}
