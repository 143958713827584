import { Component } from '@opten/compiler/metadata/component';
import { Slider, SliderOptions } from '@opten/slider';
import { SliderArrowsResponsiveBehavior } from '@opten/slider/arrows-responsive-behavior';
import { SliderCollapseBehavior } from '@opten/slider/collapse-slider-behavior';
import { SliderDotsResponsiveBehavior } from '@opten/slider/dots-responsive-behavior';
import { SliderAutoHeightBehavior } from '@opten/slider/autoheight-behavior';
import { debounce } from '@opten/reactive/debounce'

@Component({ className: 'js-slider', observe: true })
export class SliderCustom {
	private slider: Slider;
	private sliderOptions: SliderOptions;
	constructor(public element: HTMLElement) {
        // this.sliderOptions.classNameNextCtrl = "js-next";
        // this.sliderOptions.classNamePrevCtrl = "js-prev";

		const behaviors = [
			new SliderDotsResponsiveBehavior(),
			new SliderArrowsResponsiveBehavior(),
			new SliderCollapseBehavior(),
			new SliderAutoHeightBehavior()
		];

		this.slider = new Slider(element, {}, behaviors);

        
		if (this.slider.next) {
			this.slider.next.addEventListener('click', (e) => e.preventDefault());
			this.setImageHight();
		}
		if (this.slider.prev) {
			this.slider.prev.addEventListener('click', (e) => e.preventDefault());
			this.setImageHight();
		}

		window.addEventListener("resize", debounce((event) => {
			this.setImageHight();
		}, 100));

		window.addEventListener("load", debounce((event) => {
			this.setImageHight();
		}, 100));

		window.addEventListener('DOMContentLoaded', debounce((event) => {
			this.setImageHight();
		}, 100));
	}

	public setImageHight() {
		let heightOfImg: number =
			document.querySelector('.js-slide').clientHeight;
		let el = document.querySelectorAll<HTMLElement>('.js-slides');

		var i;
		for (i = 0; i < el.length; i++) {
			el[i].style.height = heightOfImg + 'px';
		}
	}
}
